@import "styles/constants.css";

.grayBackground {
  background-color: var(--color-gray-2) !important
}

.grayTextColor {
  color: var(--color-gray-2) !important
}

.grayBoxShadow {
  box-shadow: inset 0 0 0 1.5px var(--color-gray-2) !important;
}
