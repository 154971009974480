.cursor {
  cursor: pointer;
}

.transparentButton {
  border: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
}

.menuPlanDivider {
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right, #ffffff, #a1a8af, #a1a8af, #a1a8af, #ffffff);
}

.hiddenInPrint {
  @media print {
    display: none;
  }
}

.hiddenOnScreen {
  @media screen {
    display: none;
  }
}
