:root {
  --color-green: #22c55e;
  --color-white: #fff;
  --color-blue: #119cd8;
  --color-blue-1: #32b6ef;
  --color-gray-3: #e3e6e9;
  --color-gray-2: #a1a8af;
  --font-size-default: 13px;
  --g: 40px;
}
