.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-content {
  opacity: 0;
  visibility: hidden;
  width: 22rem;
  background-color: white;
  border-radius: 0.5rem;
  padding: 1.2rem;
  position: absolute;
  bottom: 180%;
  left: -3rem;
  box-shadow: 0 0.2rem 0.6rem rgba(0, 0, 0, 0.4);
  user-select: none;
}

.tooltip-content::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 4.3rem;
  margin-left: -5px;
  border-width: 10px;
  border-style: solid;
  filter: drop-shadow(0 0.4rem 0.18rem rgba(0, 0, 0, 0.3));
  border-color: white transparent transparent transparent;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.tooltip-container:hover .tooltip-content {
  visibility: visible;
  opacity: 1;
  animation: fadeIn 0.3s linear;
}

.tooltip-content.fade-out {
  animation: fadeOut 0.2s linear forwards;
}
