@import "styles/constants.css";

.image {
  height: 120px;
}

.dietFilter {
  height: 80px
}

.recipeGrid :global(.ais-infiniteHits) {
  width: 100%
}

.recipeGrid :global(.ais-InfiniteHits-list) {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.recipeGrid :global(.ais-InfiniteHits-item) {
  width: 100%;
  margin-bottom: 0;
  height: 265px;
  margin-top: calc(var(--g) / 2);
  padding-left: calc(var(--g) / 4);
  padding-right: calc(var(--g) / 4);
  

}

@media (min-width: 992px) {
.recipeGrid :global(.ais-InfiniteHits-item) {
    width: 33.3333%;
  }
}
