.source {
  padding-top: 2px;
  padding-bottom: 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sourceWidth {
  width: 200px
}

@media (max-width: 768px) {
  .sourceWidth {
    width: 120px;
  }
}
