@import "styles/constants.css";
@import "styles/icons.css";

.checkmarkIcon {
  position: absolute;
  top: -8px;
  right: -8px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: var(--color-green) no-repeat center/12px var(--checkmark);
}
